<template>
  <div>
    <ExtendedView
      :tabs="tabs"
      :selected-object="selectedMarketplace"
      @clearSelectedObject="selectedMarketplace = null"
    >
      <template v-slot:list="{ item }">
        <ActionList
          v-if="item.key === 'all'"
          :key="item.key"
          :filters="[]"
          :load-items-callback="getMarketplaces"
          @actionSuccess="getMarketplaces"
          @actionFailure="getMarketplaces"
          empty-icon="mdi-cog"
          :empty-text="$t('noMarketplacesFound')"
          v-on="$listeners"
          :call-to-actions="callToActions"
        >
          <template v-slot:item="{ item, index }">
            <MarketplaceListItem
              :marketplace="item"
              :key="item.id"
              @selectMarketplace="selectMarketplace"
            />
            <v-divider
              inset
              :key="index"
            />
          </template>
        </ActionList>
      </template>
      <template slot="profile">
        <MarketplaceProfile
          v-if="selectedMarketplace"
          :marketplace="selectedMarketplace"
        />
      </template>
    </ExtendedView>
  </div>
</template>
  
  <script>
  export default {
      name: 'Marketplaces',
      components: {
        'MarketplaceListItem': () => import('@/components/marketplace/MarketplaceListItem.vue'),
        'MarketplaceProfile': () => import('@/components/marketplace/MarketplaceProfile.vue'),
        'ExtendedView': () => import('@/components/common/ExtendedView.vue'),
        'ActionList': () => import('@/components/common/list/ActionList')
      },
      data() {
        return {
          selectedMarketplace: null, 
          callToActions: [
            {
              title: this.$t('addMarketplace'), 
              callback: this.showMarketplaceDialog
            } 
          ]
        }
      }, 
      methods: {
        selectMarketplace(marketplace) {
          this.selectedMarketplace = marketplace
        },
        getMarketplaces(limit, skip, conditions) {
          return this.$store.dispatch('getMarketplaces')
        }, 
        showMarketplaceDialog() {
          this.$store.commit('updateDataToMutate', {
            title: this.$t('createMarketplace'),
            objectToMutate: {}, 
            //SVG's can be cause the URL to be too large for the preview to work
            preventPreviewProperties: ['brightLogo', 'darkLogo', 'appIcon'],
            formComponent: 'components/marketplace/MarketplaceForm', 
            previewUrl: 'https://marketplace.salescloud.is/#/welcome',
            previewType: 'mobile',
            saveCallback: function(newMarketplace) {
              this.$store.dispatch('createMarketplace', newMarketplace).then(result => {
                if(result) {
                  this.$store.commit('updateActionSuccess', {
                    message: this.$t('success'), 
                    subMessage: this.$t('successfullyCreatedMarketplace'),  
                  })
                } else {
                  this.$store.commit('updateActionError', {
                    message: this.$t('error'), 
                    subMessage: this.$t('couldNotCreateMarketplace')
                  })
                }
              }).catch(() => {
                this.$store.commit('updateActionError', {
                  message: this.$t('error'), 
                  subMessage: this.$t('couldNotCreateMarketplace')
                })
              })
            }
          })
        }
      }, 
      computed: {
        tabs() {
          return [
            {
              title: this.$t('all'),
              key: 'all'
            }
          ]
        },
        lastUpdatedObject() {
          return this.$store.state.lastUpdatedObject
        }
      },
      watch: {
        lastUpdatedObject: {
          handler(value) {
            if(this.selectedMarketplace.id === value.id) {
              for(let key in value){
                this.selectedMarketplace[key] = value[key]
              }
            }
          }, 
          deep: true
        }
      },
      mounted() {
        this.$store.commit('updateAppBarTabs', this.tabs)
        this.$store.commit('updateSelectedAppBarTab', this.tabs[0].key)
        },
      created() {
        this.$store.commit('updateAppBarExport', {
          color: 'pink',
          callback: this.exportPayments
        })
      }
  }
  
  </script>
  